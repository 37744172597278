"use strict";
/**
 * Returns a timeout that when triggered sends the `loader:toggle` event.
 */
const loaderTimeout = (machine, timeout = 300) => {
    return setTimeout(() => {
        machine.state.loader === 'off' && machine.emit('loader:toggle');
    }, timeout);
};
module.exports = loaderTimeout;
