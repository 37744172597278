// list of most common genre/tags
module.exports = [
    'ambient',
    'acoustic',
    'alternative',
    'chill',
    'dream-pop',
    'electro',
    'electronic',
    'experimental',
    'folk',
    'funk',
    'hiphop',
    'house',
    'indie-pop',
    'indie-rock',
    'instrumental',
    'jazz',
    'metal',
    'podcasts',
    'pop',
    'punk',
    'reggae'
];
