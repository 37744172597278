const html = require('choo/html');
module.exports = ProfileMembershipView;
function ProfileMembershipView() {
    return (state, emit) => {
        return html `
      <section class="flex flex-auto flex-column w-100 pb6">

      </section>
    `;
    };
}
