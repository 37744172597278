"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-var-requires
const html = require('choo/html');
const start = (view) => {
    return (state, emit) => {
        return html `
      <main class="flex flex-row flex-auto w-100">
        ${view(state, emit)}
      </main>
    `;
    };
};
exports.default = start;
